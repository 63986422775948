export const API_URL = 'https://order.ellaapp.com/admin/web/v1/';
export const API_URL2 = 'https://order.ellaapp.com/admin/web/';
export const LOCAL_PAYMENT_API_URL = "https://192.168.10.11";
export const LOCAL_PAYMENT_PORT = "82";
export const SUICA_SERVER = "7700";
export const PRINT_TIMEOUT = 20000;
export const telemetryTimeout = 5000;
export const setIntervalTimeOut =1000;
export const QRcodeTimeout = 10000;
export const cart_time = 30;
export const payment_timer = 30;
export const modalTimeout = 6000;
export const PRINT_API_URL = "https://localhost:3333/?purl=";
export const KIOSK_AUTO_LOGIN ="https://sapkiosk.ellaapp.com/kioskautologin/50af53b1204c9fcccca2fb30418ec62b/c82e0883765324a5c36e921efdb082db";
export const page_redirection_time = 30;
export const auto_logout_timer = 20;
export const timer_time = 30;
export const menu_items_timer = 20000;
export const images_display_timer = 15000;
