import { Component } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertController, ModalController } from '@ionic/angular';
import { TranslateConfigService } from '../services/translate-config.service';
import { UtilityService } from '../services/utility.service';
import { ApiService } from '../services/api.service';
import { modalTimeout, page_redirection_time, setIntervalTimeOut, telemetryTimeout, cart_time, KIOSK_AUTO_LOGIN, timer_time, images_display_timer, menu_items_timer } from '../common/api';
import { TranslateService } from '@ngx-translate/core';
import { CartItemDetailPage } from '../cart-item-detail/cart-item-detail.page';
import { PageLoaderService } from '../services/page-loader.service';
import { environment } from 'src/environments/environment';
import { KioskapiService } from '../services/kioskapi.service';




@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  params: any;
  telemetry_blocker = false;
  forced_transmission = "";
  payment_progress_txt = "";
  payment_progress = "";
  payment_terminal_status = "";
  payment_terminal_status_txt = "";
  firmware_updating = false;
  software_version = "";
  ordering_avail = true;
  time_check = "OK";
  payment_initiated = false;
  check_nega = false;
  wait_card = false;
  telemetry_poller: any;
  maintain_timer: any;
  is_maintaince_mode_on = true;
  appSettings: any;
  shop: any;
  type = "0"
  menuItems = {};
  totalAmount: number;
  settings = {};
  orderType = "";
  currency = "¥";
  showDecimal = true;
  cartItems: [];
  cartItemCount: number;
  totalCartAmount: number = 0;
  payment: any;
  timer_start = true;
  menu_item: boolean;
  page_redirection_time_left = page_redirection_time;
  time_left_redirection_counter: any;
  is_restaurant_closed_status = "NO";
  time_left_counter: any;
  time_left = 0;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  obj_restaurantstatus;
  store_close_status;
  restaurant_mode;
  obj_menuItems;
  obj_masterrestaurant
  obj_restaurantoperational
  obj_storeinfo
  restaurant_status_interval: any;
  obj_restaurantDetails;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  terminalResponse: any;
  hide_cancel_button: any;
  payprog_lock: any;
  subtype: any;
  deducted: any;
  attempted: any; obj_AppSetting;
  app_settings;
  cartItems_create_order: any = [];
  ServiceID: any;
  isPaymentFailed;
  balance: any;
  createorderRes;
  order_UUID;
  proc_card_idi;
  terminalId;
  ella_limit;
  counter = 0
  home_banner_image:any;
  display_images:any;
  menuItems_timer:any;
  menuItems_length;
  one_pager_kiosk: any;
  cat_show: string;
  menuItems_withoutCat:any = []
  
  constructor(
    private router: Router,
    private kioskauthservice: KioskauthService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private store: LocalStoreService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private api: ApiService,
    public alertController: AlertController,
    private translate: TranslateService,
    private pageLoaderService: PageLoaderService,
    private kiosk_apiServices : KioskapiService
   
  ) {
    this.translateConfigService.getDefaultLanguage();
    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
  
  }

  ngOnInit() {
    this.is_restaurant_closed();
    this.getRestaurantDetails();
    this.menu_item = false;
    this.check_auth();
    this.time_stop = false;
    this.time_left = cart_time;
    clearInterval(this.time_left_counter);
    clearInterval(this.maintain_timer);
    this.getAppSettings();
    this.app_setting();
  }

  is_restaurant_closed() {
    let restaurantDetails = this.store.getRestaurant();
    let param = {
      "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
    }
    this.obj_restaurantstatus = this.kiosk_apiServices.restaurant_Operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.is_restaurant_closed_status = "NO";
            } else {
              this.is_restaurant_closed_status = "YES";
            }
            // localStorage.removeItem('restaurant');
            // this.store.setRestaurant(res.aaData);
          }
          if (res.statusCode != 200) {
            if (res.aaData == false) {
              this.technical_error()
            }
          }
        },
        error => {
          // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
          this.technical_error()
          console.log(error)
        }
      );
  }

  ionViewWillEnter() {
    this.counter = 0
    this.ella_drink_limit()
    this.terminalId = this.store.get('terminalid')
    this.getMenuItems();
    this.reset_time();
    this.store_close_status = setInterval(
      () => {
        this.is_restaurant_closed();
      }
      , 10000);
    this.restaurant_mode = setInterval(
      () => {
        this.getRestaurantDetails();
      }
      , 12000);
    this.clock_Timer();
    this.orderType = this.store.get('order_type');
    // this.check_auth();
    this.getCartItems();
    if (this, this.cartItemCount == 0) {
      clearInterval(this.time_left_counter);
    }
    // this.display_images =setInterval(()=>{
    //   this.getAppSettings();
    // },images_display_timer)
    this.menuItems_timer =setInterval(()=>{
      this.getMenuItems();
    },menu_items_timer)

    this.kiosk_apiServices.audit_log_events("HOME")
    this.kiosk_apiServices.log_events("HOME")
  }

  getMenuItems() {
    //Get Menu Details
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
    let user_information_uuid = localStorage.getItem("user_information_uuid");
    let payload = { "ref": "kiosk", "master_restaurant_uuid": master_restaurant_uuid, "user_information_uuid": user_information_uuid };
    // this.pageLoaderService.pageLoaderPersistent(true)  //----------loader
    this.obj_menuItems = this.kiosk_apiServices.Menu_items()
      .subscribe((response) => {
        // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          if(this.one_pager_kiosk == false || this.one_pager_kiosk == "false" || this.one_pager_kiosk == null || this.one_pager_kiosk == undefined){
            if (response.final_response) {
              this.cat_show = "true";
              this.menu_item = true;
              this.menuItems = response.final_response;
              this.menuItems_length = Object.keys(this.menuItems).length
              
            } else {
              this.menu_item = true;
              this.cat_show = "true";
              this.menuItems = response.aaData;
              this.menuItems_length = Object.keys(this.menuItems).length
            }
          }else{
            if (response.final_response) {
              this.menu_item = true;
              this.cat_show = "false";
              this.menuItems_withoutCat = response.final_response;
              this.menuItems_length = Object.keys(this.menuItems).length
              
            } else {
              this.cat_show = "false";
              this.menu_item = true;
              this.menuItems_withoutCat = response.aaData;
              this.menuItems_length = Object.keys(this.menuItems).length
            }
          }
        }
      },
        error => {
          // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
          this.technical_error()
          console.log(error)
        }
      );
  }

  check_auth() {
    if (!this.kioskauthservice.is_logged_in()) {
      this.kioskauthservice.logout();
    }
  }

  async redirectOnDetailPage(item) {
    // this.redirectOnItemDetailPage(item);
    let restaurantDetails = this.store.getRestaurant();
    let param = {
      "ref": "kiosk",
      "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
    }
    // this.pageLoaderService.pageLoaderPersistent(true)  //----------loader
    this.obj_masterrestaurant = this.kiosk_apiServices.restaurant_Operational()
      .subscribe(
        async (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.redirectOnItemDetailPage(item);
            } else {
              this.kioskauthservice.isRestaurantOpenAlert();
              return false;
            }
          }
          if (res.statusCode != 200) {
            if (res.aaData == false) {
              this.technical_error()
            }
          }
        },
        error => {
          // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
          console.log(error.status)
          this.technical_error()
        }
      );
  }

  async redirectOnItemDetailPage(item) {
    if (item.is_food_category_time_available == false) {
      this.kioskauthservice.itemNotAvailableAlert();
      return false;
    } else {
      if (item.master_menu_item_is_combo == "YES") {
        if (item.master_food_category_uuid == '12844a2db14f510f90333983f1fa07ad' || item.master_food_category_uuid == '1be5953589919b2b686f94e2b68dccea' || item.master_food_category_uuid == 'a16b3341e0203ea1e3435c5c04b177bd') {
          this.router.navigateByUrl(`product-combo-detail?id=` + item.master_menu_item_uuid);
        }
        else {
          // const modal = await this.modalController.create({
          //   component: PleaseSelectPage,
          //   cssClass: 'please-select-modal',
          //   mode: 'md',
          //   componentProps: {
          //     data : {
          //       item,
          //     }
          //   },
          // });
          // return await modal.present();
          this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
        }
        // this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
      }
      else {
        this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
      }
    }
  }

  getCartItems() {

    let cartDetail = this.store.getUserCart();
    this.cartItems = cartDetail;
    this.cartItemCount = 0;
    this.totalCartAmount = 0;

    Object.entries(cartDetail).forEach(([k, v]) => {
      this.cartItemCount = +v[0].total_quantity + +this.cartItemCount;
      this.totalCartAmount = +v[0].total + +this.totalCartAmount;
      if (this.timer_start == true || this.cartItemCount == 0) {

        this.time_left = cart_time;
        clearInterval(this.time_left_counter);

      }

      if (this.cartItemCount != 0) {

        this.time_left_counter = setInterval(() => {
          this.time_left -= 1;
          if (this.time_left == 0) {
            clearInterval(this.time_left_counter);
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.alertController.dismiss(); 
            let Autologin = KIOSK_AUTO_LOGIN
            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalId;
  
            return false;
          }
        }, setIntervalTimeOut);
      }

    })

  }

  getTotalQuantity(item) {
    return item.total_quantity;
  }

  checkOut() {
    if(this.app_settings.enable_kiosk_payment){
      let restaurantDetails = this.store.getRestaurant();
      let param = {
        "ref": "kiosk",
        "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
      }
      // this.pageLoaderService.pageLoaderPersistent(true)  //----------loader
      this.obj_restaurantoperational = this.kiosk_apiServices.restaurant_Operational()
        .subscribe(
          (res) => {
            // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
            if (res.statusCode == 200) {
              if (res.aaData == true) {
                clearInterval(this.time_left_counter);
                this.router.navigate([`payment-method`]);
              } else {
                this.kioskauthservice.isRestaurantOpenAlert();
                return false;
              }
            }
            if (res.statusCode != 200) {
              if (res.aaData == false) {
                this.technical_error()
              }
            }
          },
          error => {
            // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
            console.log(error)
            this.technical_error()
          }
        );
    }
    else{
     this.checkout_orderCreate()
    }

  }

  removeItemFromCart(index) {
    let cart = this.store.getUserCart();
    cart.splice(index, 1);
    this.store.setUserCart(cart);
    this.ionViewWillEnter();
    this.kiosk_apiServices.log_events("CART_ITEM_REMOVED");
    this.kiosk_apiServices.audit_log_events("CART_ITEM_REMOVED");

  }

  editItem(index, itemUuid) {
    this.router.navigateByUrl('/product-detail?id=' + itemUuid + '&ci=' + index);
  }

  /**
   * Function to add pull down feature 
   * @param event 
   */

  doRefresh(event) {
    let param = {
      "ref": "kiosk",
      master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid")
    }
    // this.pageLoaderService.pageLoaderPersistent(true)  //----------loader
    this.obj_storeinfo = this.kiosk_apiServices.restaurant_Details()
      .subscribe((res) => {
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          event.target.complete();
          this.maintain_timer = setInterval(
            () => {
              if (this.shop.master_restaurant_status == 'M_ON') {
                this.is_maintaince_mode_on = true;
              }
              else {
                this.is_maintaince_mode_on = false;
              }
              this.is_maintaince_mode_on = false;
            }
            , telemetryTimeout);
        } else {
          event.target.complete();
        }
        if (res.statusCode != 200) {
          if (res.aaData == false) {
            this.technical_error()
          }
        }
      }, err => {
        event.target.complete();
        // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
      });
  }

  getRestaurantDetails() {
    let param = {
      "ref": "kiosk",
      master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid")
    }
    // this.pageLoaderService.pageLoaderPersistent(true)  //----------loader
    this.obj_restaurantDetails = this.kiosk_apiServices.restaurant_Details()
      .subscribe((res) => {
        // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          if (this.shop.master_restaurant_status == 'M_ON') {
            this.is_maintaince_mode_on = true;
          }
          else {
            this.is_maintaince_mode_on = false;
          }
        }
        if (res.statusCode != 200) {
          if (res.aaData == false) {
            this.technical_error()
          }
        }
      }
        , error => {
          // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader.
          console.log(error)
          this.technical_error()
        });
  }

  /**
 * Function to get app seting
 */
   getAppSettings() {

  this.kiosk_apiServices.appsettings().subscribe(response => {
      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;

        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description :  environment.M_ON;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
        this.home_banner_image = response.aaData.app_settings.app_store_banner_description_kiosk 
      }
    }, err => {

    });
  }


  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  show_payment_simulation() {
    this.router.navigateByUrl('/payment-method2');
  }

  ionViewDidLeave() {
    this.counter = 0
    clearInterval(this.time_left_redirection_counter);
    this.obj_restaurantDetails.unsubscribe();
    this.obj_restaurantstatus.unsubscribe();
    this.obj_menuItems.unsubscribe();
    // this.obj_masterrestaurant.unsubscribe();
    this.obj_restaurantoperational.unsubscribe();
    this.obj_AppSetting.unsubscribe();
    // this.obj_storeinfo.unsubscribe();
    clearInterval(this.telemetry_poller);
    clearInterval(this.time_left_counter);
    clearInterval(this.maintain_timer);
    clearInterval( this.display_images)
    clearInterval(this.menuItems_timer)

  }

  switchOrderType(type) {
    this.orderType = type;
    this.store.set("order_type", type);
  }

  async openSheetModal(item) {
    let itemData = item;
    if (item['combo_items']) {
      itemData = item['combo_items'];
    }
    const modal = await this.modalController.create({
      component: CartItemDetailPage,
      cssClass: 'app-cart-item-detail1',
      mode: 'md',
      componentProps: {
        data: itemData
      },
    });
    setTimeout(() => { modal.dismiss(); }, modalTimeout);
    return await modal.present();
  }

  clock_Timer() {
    setInterval(function () {
      var dt = new Date();
      var ampm = dt.getHours() >= 12 ? 'PM' : 'AM';
      var clock_time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds() + " " + ampm;

      if (clock_time === '7:0:0 AM' || clock_time === '7:30:0 PM' || clock_time === '19:30:0 PM') {
        // alert("helo");
        window.location.reload();
      }
    });
  }

  go_to_maintenance() {
    let terminalid = localStorage.getItem('terminalid')
    terminalid = JSON.parse(terminalid)
    location.href = "http://192.168.10.11:9001/?terminalid=" + terminalid;
  }

  reset_time() {
    this.time_left = cart_time;
    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time;
    if (this.page_redirection_time_left = page_redirection_time) {
      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;

        if (this.page_redirection_time_left == 0) {
          clearInterval(this.time_left_redirection_counter);
          this.store.setUserCart([]);
          this.store.remove("formFields");
          this.store.remove("cart");
          let Autologin = KIOSK_AUTO_LOGIN
          this.store.hardlogout()
          location.href = Autologin + '/' + this.terminalId;
          return false;
        }
      }, setIntervalTimeOut);
    }
  }

  logScrollStart(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  logScrolling(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  logScrollEnd(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  is_item_ella(item) {
    if (item.master_menu_type == 'ELLA' || item.master_food_category_uuid == '0ecd423c3da7fa02266ae19af4b4cdb') {
      return true;
    }
    else {
      return false;
    }
  }

  is_category_coffee(obj) {
    var obj2 = obj.key;
    var index = "NO";
    obj2 = obj2.split("__");
    if (obj2[1] !== undefined && obj2[1] !== null) {
      obj2 = obj2[1];
    }
    obj2 = obj2.toLowerCase();
    if (obj2 == "coffee" || obj2 == "non coffee" || obj2 == "coffee & tea" || obj2 == "breakfast") {
      index = "YES";
    }
    return index;
  }

  isItemCartAvaliable() {
    this.counter = 1
    this.kiosk_apiServices.isItemCartAvaliable().subscribe(
      (res) => {
        if (res.statusCode == 200) {
          this.checkout_orderCreate()
        }
        else {
          clearInterval(this.time_left_redirection_counter)
          clearInterval(this.time_left_counter);
          this.soldItemModal(res)
        }
      }
    )
  }

  ella_drink_limit(){
    this.kiosk_apiServices.restaurant_Operational()
    .subscribe(
      (res) => {
        this.ella_limit = res.ella_max_drinks 
      },
    );
  }



  async soldItemModal(data) {
     let itemImage = data.message.item_image
    if(itemImage == undefined || itemImage == ''){
      itemImage = "assets/image/bell.svg"
    }
    else{
      itemImage = data.message.item_image
    }
    let message = data.message.error
    let html = `<div class="custom-head">
    <span><img src="`+ itemImage + `"></span>
    <h2>Sorry</h2>
    <p>`+ message + `</p>
    </div>`
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class default-modal no-default-image',
      mode: 'md',
      message: html,
      backdropDismiss:false,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.counter = 0
            this.reset_time();
            if (this.cartItemCount != 0) {
              this.time_left_counter = setInterval(() => {
                this.time_left -= 1;
                if (this.time_left == 0) {
                  clearInterval(this.time_left_redirection_counter);
                  this.store.setUserCart([]);
                  this.store.remove("formFields");
                  this.store.remove("cart");
                  let Autologin = KIOSK_AUTO_LOGIN
                  this.store.hardlogout()
                  location.href = Autologin + '/' + this.terminalId;
                  return false;
                }
              }, setIntervalTimeOut);
            }
          }
        }
      ]
    });
    setTimeout(() => {
      this.counter = 0
      this.alertController.dismiss();
      if (this.cartItemCount != 0) {
        this.time_left_counter = setInterval(() => {
          this.time_left -= 1;
          if (this.time_left == 0) {
            clearInterval(this.time_left_redirection_counter);
          this.store.setUserCart([]);
          this.store.remove("formFields");
          this.store.remove("cart");
          let Autologin = KIOSK_AUTO_LOGIN
          this.store.hardlogout()
          location.href = Autologin + '/' + this.terminalId;
          return false;
          }
        }, setIntervalTimeOut);
      }
      this.reset_time();
    }, 5000);
    await alert.present();
  }
 
  async technical_error() {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',//'Please Tap Again',
      message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
      buttons: [
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/Scan');
          }
        }
      ]
    });
    setTimeout(() => { this.router.navigateByUrl('/Scan'), this.alertController.dismiss(); }, 5000);
    (await alert).present();
  }

//-------Payment----

app_setting() {
  let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
  let data = {
    "master_restaurant_uuid": master_restaurant_uuid
  }
  this.kiosk_apiServices.getRestaurantSetting(data)
    .subscribe(
      (res) => {
        this.app_settings = res.aaData.module_permissions
        this.one_pager_kiosk = this.app_settings.one_pager_kiosk
      },
      error => {
      }
    );
}

loadAllData() {
  const orderId = this.store.get('orderId')
  const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
  let cartDetail = this.store.getUserCart();
  this.totalAmount = 0;
  Object.entries(cartDetail).forEach(([k, v]) => {
    this.totalAmount = v['total'] + +this.totalAmount;
    Object.entries(v).forEach(([key, value]) => {
      this.cartItems_create_order.push(value);
    })
  });
}


create_kiosk_order() {
  let terminalid = this.store.get('terminalid')
  let cartDetail = this.store.getUserCart();
  this.totalAmount = 0;
  this.cartItems_create_order = [];
  Object.entries(cartDetail).forEach(([k, v]) => {
    Object.entries(v).forEach(([key, value]) => {
      if (v['total']) {
        this.totalAmount = v['total'] + +this.totalAmount;
      }
      this.cartItems_create_order.push(value);
    })
  });

  let order_type = "TAKE_AWAY";
  if (this.store.get("order_type") == "Dine In") {
    order_type = "DINE_IN";
  }
  let totalCartAmount = this.totalAmount;
  
  let user = this.store.getUser();
  this.attempted = this.totalAmount;
  this.ServiceID = this.generate_rand_num();
  let data = {
    session_id: this.store.get('sessionId'),
    order_info_uuid: this.store.get('orderId'),
    order_info_customer: user.user_information_uuid,
    master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
    order_info_total_price: totalCartAmount,
    order_info_total_order_quantity: 0,
    order_info_total_discount: 0,
    available_redeemed_amount: 0,
    points_redeemed_amount: 0,
    master_coupon_uuid: null,
    master_subscription_uuid: null,
    subscription_mapping_uuid: null,
    used_subscription_quantity: 0,
    order_info_gst: 0, //Number(this.gst.toFixed(2)),
    // order_info_service_tax [OPTIONAL]
    order_info_grand_total: totalCartAmount,
    order_info_delivery_date: "",
    master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
    orders: this.cartItems_create_order,
    order_info_table_number: null,
    order_info_car_plate_number: null,
    order_info_cutlery: 'FALSE',
    order_info_type: order_type,//this.store.get("order_type"),
    order_info_dinningmode: null,
    order_info_dinningtime: null,
    delivery_address: null,
    is_sap_order: "YES",
    country_uuid: this.store.getCountry().master_country_uuid,
    request_from: "SAP",
    deducted: this.deducted,
    balance: this.balance,
    serviceID: this.ServiceID,
  }
  this.kiosk_apiServices.audit_log_events("PAYMENT",data,"ORDER_CREATE_PAYLOAD")
  this.kiosk_apiServices.log_events("PAYMENT",data,"ORDER_CREATE_PAYLOAD")
this.kiosk_apiServices.create_kiosk_order(data).subscribe((res) => {
  this.kiosk_apiServices.audit_log_events("PAYMENT",res,"ORDER_CREATE_RES")
  this.kiosk_apiServices.log_events("PAYMENT",res,"ORDER_CREATE_RES")
    if (res.statusCode == 200) {
      // this.auditlogServices.logEvents("CHECKOUT");
      // this.auditlogServices.audit_log("CHECKOUT")
      this.updateOrder(res, null, "QUEUED");
      this.store.set("orderId", res.aaData['order_info_uuid']);
      this.store.remove("totalAmount");
      this.store.remove("totalCartAmount");
      this.store.setUserCart([]);
      this.store.remove("selectedItemsInCart");
      if (res.statusCode != 200) {
        if (res.aaData == false) {
          // this.technical_error()
        }
      }
      if (res.aaData['master_restaurant_status'] == 'M_ON') {
        this.router.navigate([`home`]);
      } else {
      }
    } else {
    }
  }, err => {
    this.counter = 0
    this.kiosk_apiServices.audit_log_events("PAYMENT",err,"ORDER_CREATE_ERR")
  this.kiosk_apiServices.log_events("PAYMENT",err,"ORDER_CREATE_ERR")
  const payment_cancel_timeout = setTimeout(() => {
    clearInterval(this.time_left_redirection_counter);
    let Autologin = KIOSK_AUTO_LOGIN
    this.store.hardlogout()
    location.href = Autologin + '/' + terminalid;
  }, 100)
  })
  
}



 
  updateOrder(orderData, paymentResponse, isPaymentFailed) {
    let terminalid = this.store.get('terminalid')
    this.order_UUID = orderData.aaData.order_info_uuid
    this.isPaymentFailed = isPaymentFailed
    let data = {
      order_info_uuid: this.order_UUID,
      restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      payment_response: paymentResponse,
      payment_status: isPaymentFailed ? isPaymentFailed : '',
    }
    this.kiosk_apiServices.audit_log_events("PAYMENT",data,"UPDATE_ORDER_PAYLOAD")
    this.kiosk_apiServices.log_events("PAYMENT",data,"UPDATE_ORDER_PAYLOAD")
    this.kiosk_apiServices.updateOrder(data).subscribe(res => {
      this.kiosk_apiServices.audit_log_events("PAYMENT",res,"UPDATE_ORDER_RES")
      this.kiosk_apiServices.log_events("PAYMENT",res,"UPDATE_ORDER_RES")
      // if(res.statusCode == 200) {
      this.store.remove("orderId");
      this.store.remove("totalAmount");
      this.store.remove("totalCartAmount");
      this.store.setCart([]);
      this.store.remove("selectedItemsInCart");
      //   this.router.navigate([`settlement-complete`]);
      if (this.isPaymentFailed == 'QUEUED' && this.app_settings.enable_kiosk_print) {
        this.router.navigateByUrl('/settlement-complete?id=' + this.order_UUID + "&c=" + this.proc_card_idi + "&b=");
      }
      else if(!this.app_settings.enable_kiosk_print && this.isPaymentFailed == 'QUEUED'){
        const payment_cancel_timeout = setTimeout(() => {
          clearInterval(this.time_left_redirection_counter);
          let Autologin = KIOSK_AUTO_LOGIN
          this.store.hardlogout()
          location.href = Autologin + '/' + terminalid;
        }, 100)
  
      }
      return false;
      //} 
    }, err => {
      this.counter = 0
      this.kiosk_apiServices.audit_log_events("PAYMENT",err,"UPDATE_ORDER_ERR")
      this.kiosk_apiServices.log_events("PAYMENT",err,"UPDATE_ORDER_ERR")
      const payment_cancel_timeout = setTimeout(() => {
        clearInterval(this.time_left_redirection_counter);
        let Autologin = KIOSK_AUTO_LOGIN
        this.store.hardlogout()
        location.href = Autologin + '/' + terminalid;
      }, 100)
    }
    );
  }

  generate_rand_num() {
    var rand: any;
    rand = Math.random();
    rand = rand * 10000000000;
    rand = Math.floor(rand);
    rand = '' + rand;
    rand = rand.substr(0, 8);
    return rand;
  }

checkout_orderCreate(){
  this.kiosk_apiServices.audit_log_events("CHECKOUT")
  this.kiosk_apiServices.log_events("CHECKOUT")
  this.loadAllData()
  this.create_kiosk_order()
}
} 
