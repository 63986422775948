import { Component, OnInit } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStoreService } from '../services/localstore.service';
import { UtilityService } from '../services/utility.service';
import { environment } from 'src/environments/environment';
import { KioskapiService } from '../services/kioskapi.service';
import { images_display_timer } from '../common/api';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.page.html',
  styleUrls: ['./scan.page.scss'],
})
export class ScanPage implements OnInit {
  is_maintaince_mode_on = "true";
  appSettings: any;
  shop: any;
  type="0"
  menuItems={};
  totalAmount: number;
  settings={};
  orderType = "";
  currency = "¥";
  showDecimal= true;
  cartItems: [];
  cartItemCount: number;
  totalCartAmount: number = 0;
  payment:any;
  timer_start=true;
  telemetry_poller:any;
  is_restaurant_closed_status = "NO";
  maintain_mode;
  store_close_status;
  restaurant_mode;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  obj_restaurantstatus;
  obj_restaurantDetails;
  store_info:any;
  is_ella_maintaince_mode_on:boolean
  kiosk_start_screen_image:any;
  display_images:any;
  
  constructor(private router: Router,
    private store:LocalStoreService,
    private utilityService: UtilityService,
    private kioskauthservice:KioskauthService,
    private kiosk_apiService : KioskapiService) {
         this.is_maintaince_mode_on = "true";
         this.is_restaurant_closed_status = "NO"


     }

     ionViewWillEnter() {
      this.is_maintaince_mode_on = "true";
      this.is_restaurant_closed_status = "NO" 
      this.getRestaurantDetails();
      this.is_restaurant_closed();
      this.getAppSettings();
      this.store_close_status = setInterval(
        () => {
          this.is_restaurant_closed();
        }
        , 10000);
      this.restaurant_mode = setInterval(
        () => {
          this.getRestaurantDetails();
        }
        , 12000);
        // this.display_images =setInterval(()=>{
        //   this.getAppSettings();
        // },images_display_timer)
  
       
    }
  
    ngOnInit() {
      this.is_restaurant_closed_status = "NO"
      this.is_maintaince_mode_on = "true";
      this.kiosk_apiService.log_events("SCAN")
      this.kiosk_apiService.audit_log_events("SCAN")
    }
  
  getRestaurantDetails(){
    let param = {
      "ref":"kiosk",
        master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid")
    }
    // this.pageLoaderService.pageLoaderPersistent(true)  //----------loader

   this.obj_restaurantDetails = this.kioskauthservice.apiReq('post', 'masterrestaurant/getbyuuid', param)
        .subscribe((res) => {
          // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant',res.aaData);
          this.store_info = res.aaData
          if(this.store_info.master_restaurant_status == "ELLA_M_ON"){
            this.is_ella_maintaince_mode_on = true;
          }
          else{
            this.is_ella_maintaince_mode_on = false;
          }
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          //this.getAppSettings();
          // this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          if (this.shop.master_restaurant_status == 'ACTIVE') {
            this.is_maintaince_mode_on = "false";
          }else if (this.shop.master_restaurant_status != 'M_ON'){
            this.is_maintaince_mode_on = "false";
          }
          else {
            this.is_maintaince_mode_on = "true";
          }     

        } 
    }, error => {
      // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader.

    });
  }
 
  getAppSettings() {

    this.kiosk_apiService.appsettings().subscribe(response => {
        if (response.status) {
          this.appSettings = response.aaData;
          this.settings = this.appSettings;
  
          this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description :  environment.M_ON;
          this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
          this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
          this.kiosk_start_screen_image = response.aaData.app_settings.app_store_scan_description_kiosk

        }
      }, err => {
  
      });
    }
  go_to_maintenance() {
    location.href = "http://192.168.10.11:9001";
  }

  
  is_restaurant_closed(){
   this.obj_restaurantstatus = this.kiosk_apiService.restaurant_Operational()
      .subscribe(
          (res) => {
              if (res.statusCode == 200) {
                if(res.aaData == false) {
                  this.is_restaurant_closed_status= "YES";
                } else {                  
                  this.is_restaurant_closed_status= "NO";
               }
                  // localStorage.removeItem('restaurant');
            // this.store.setRestaurant(res.aaData);
        } 
    },
    );
 }



 ionViewDidLeave() {
  clearInterval(this.store_close_status);
  clearInterval(this.telemetry_poller);
  clearInterval(this.restaurant_mode)
  clearInterval( this.display_images);
  this.obj_restaurantstatus.unsubscribe();
  this.obj_restaurantDetails.unsubscribe();
}
}
