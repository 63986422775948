import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PageLoaderService } from './page-loader.service';
import { API_URL,LOCAL_PAYMENT_API_URL, LOCAL_PAYMENT_PORT } from '../common/api';
import { LocalStoreService } from '../services/localstore.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { getAnalytics, logEvent } from "firebase/analytics";


@Injectable({
  providedIn: 'root'
})
export class KioskapiService {
  cartItems:any =[]
  constructor(
    private httpClient: HttpClient,
    private toastController: ToastController,
    private pageLoaderService: PageLoaderService,
    private router: Router,
    private store: LocalStoreService,
    private modalController: ModalController,
    private translate: TranslateService,
    public alertController: AlertController,
  ) { }

  apiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    let user = this.store.get('user')
    let options: any = {
    headers: new HttpHeaders({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${user.token}`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Accept-Language': this.store.getLanguageUuid(),
    'Accept-Language-Code': this.store.getLanguageCode()
    })
    }
    switch (method) {
    case 'get':
    return this.httpClient.get(API_URL + endPoint, options );
    case 'post':
    return this.httpClient.post(API_URL + endPoint, payload,options);
    case 'put':
    return this.httpClient.put(API_URL + endPoint, payload);
    case 'patch':
    return this.httpClient.patch(API_URL + endPoint, payload);
    case 'delete':
    return this.httpClient.delete(API_URL + endPoint, payload);
    default:
    return null;
    }
    }


    Menu_items():Observable<any>{
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
  let user_information_uuid = localStorage.getItem("user_information_uuid");
  let payload = { "ref": "kiosk", "master_restaurant_uuid": master_restaurant_uuid, "user_information_uuid": user_information_uuid };
  // this.pageLoaderService.pageLoaderPersistent(true) //------------loader
  // return this.apiReq('post', 'mastermenuitem/getcategorymenuitemsbyrestaurantuuid', payload)
  return this.apiReq('post', 'mastermenuitem/getpagermenuitemsbyrestaurantuuid', payload)
    }
  
    restaurant_Operational():Observable<any>{
      let restaurantDetails = this.store.getRestaurant();
      let param = {
      "ref": "kiosk",
      "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
      }
      return this.apiReq('post', 'masterrestaurant/isrestaurantoperational', param)
    }
  
    restaurant_Details():Observable<any>{
      const payload ={
        "ref": "kiosk",
        'master_restaurant_uuid': localStorage.getItem("master_restaurant_uuid"),
      }
      return this.apiReq('post', 'masterrestaurant/getbyuuid', payload);
     }
  
     kiosk_settings():Observable<any>{
      let countryData = this.store.getCountry();
      let countryUuid = countryData['master_country_uuid'];
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
      return this.apiReq('get', 'payment/kiosksetting?cid=' + countryUuid)
    }

    isItemCartAvaliable():Observable<any>{
      let restaurantDetails = this.store.getRestaurant();
      let orderitem = this.store.getUserCart();
      this.cartItems = [];
      Object.entries(orderitem).forEach(([k, v]) => {
        Object.entries(v).forEach(([key, value]) => {
          this.cartItems.push(value);
        })
      });
      let data = {
        ref: "kiosk",
        master_restaurant_uuid: restaurantDetails.master_restaurant_uuid,
        orders:   this.cartItems
      }
      return this.apiReq('post', 'orderinfo/isitemavailable', data);
     }
  
     appsettings():Observable<any>{
      let countryData = this.store.getCountry();
      let countryUuid = countryData['master_country_uuid'];
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
      return this.apiReq('get', 'payment/appsetting?cid=' + countryUuid +'&rid='+master_restaurant_uuid+'&type='+"kiosk")
    }

    getRestaurantSetting(data):Observable<any>{
      return this.apiReq('post', 'masterrestaurant/getrestaurantsetting', data)
    }
  
  create_kiosk_order(data):Observable<any>{
      return this.apiReq('post', 'orderinfo/createkioskorder', data)
    }
  
    Item_detail(data):Observable<any>{
      return this.apiReq('post', 'mastermenuitem/getitemdetailbyitemuuid',data)
      }
  
      
    update_order(data):Observable<any>{
      return  this.apiReq('post','orderinfo/updatekioskorderstatus',data)
     }
 

      updateOrder(data):Observable<any>{
        return this.apiReq('post', 'orderinfo/updatekioskorderinfostatus', data);
       }

       order_details(data):Observable<any>{
        return  this.apiReq('post', 'orderinfo/getbyuuid', data)
        }
    

  audit_log_events(screen,payment?,payment_status?){
    let payment_response =Object.assign({},payment );
  let restaurant = this.store.get("restaurant")
  const payload = {
    "terminal_id": this.store.get("terminalid") ,
    "session_id": this.store.get("sessionId"),
    "restaurant_uuid": restaurant.master_restaurant_uuid,
    "restaurant_status":restaurant.master_restaurant_status,
    "request_from": screen,
    "cart": this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : [],
    "payment": payment_response,
    "payment_status":payment_status
  }
  
  this.apiReq('post', 'ssouserinformation/kioskauditlog', payload)
  .subscribe(
  (res) => {
  }, error =>{
  },
  );
   
  }
    
  log_events(screen,payment?, payment_status?) {
    let payment_response = Object.assign({},payment);
    let restaurant = this.store.get("restaurant")
    let terminalid = this.store.get("terminalid")
    let session_id = this.store.get("sessionId")
    const analytics = getAnalytics();
    logEvent(analytics, 'logEvents', {
      "terminal_id":terminalid ,
      "session_id": session_id,
      "restaurant_uuid": restaurant.master_restaurant_uuid,
      "restaurant_status":restaurant.master_restaurant_status,
      "request_from": screen,
      "cart": this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : {},
      "payment": payment_response,
      "payment_status":payment_status
    })
  }

}
