import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthguardserviceService {

  constructor() { }




  is_logged_in(){
   let master_restaurant_uuid= localStorage.getItem("master_restaurant_uuid");
   let user_information_uuid= localStorage.getItem("user_information_uuid");
   if( master_restaurant_uuid !=null && master_restaurant_uuid !=undefined && user_information_uuid !=null && user_information_uuid !=undefined ){
       return true;
   }
   else{
       return false;
   }
   return false
 }  
 
 }
 