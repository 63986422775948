import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateConfigService } from '../services/translate-config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { API_URL2, auto_logout_timer, KIOSK_AUTO_LOGIN, page_redirection_time, PRINT_API_URL } from '../common/api';
import { API_URL, payment_timer, PRINT_TIMEOUT, QRcodeTimeout, setIntervalTimeOut } from '../common/api';
import { ApiService } from '../services/api.service';
import { PageLoaderService } from '../services/page-loader.service';
import { KioskapiService } from '../services/kioskapi.service';

@Component({
  selector: 'app-settlement-complete',
  templateUrl: './settlement-complete.page.html',
  styleUrls: ['./settlement-complete.page.scss'],
})
export class SettlementCompletePage implements OnInit {
  orderInfo: any = [];
  paymentResponse: any = [];
  currency = "¥";
  showDecimal= true;
  print_url;
  render_order_number:any = "";
  order_info_uuid: string;
  card_idi: string;
  bal: string;
  QR_order_info_uuid: string;
  printUrl: string;
  time_left_counter : any;
  time_left = 0;
  time_stop = false;
  mid_cancel = false;
  messageSuccess = true;
  ORDER_UUID;

  constructor(
    private httpClient: HttpClient,
    private api: ApiService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private kioskauthservice:KioskauthService,
    private route: ActivatedRoute,
    private store:LocalStoreService,
    public alertController: AlertController,
    private translateConfigService: TranslateConfigService,
    private pageLoaderService: PageLoaderService,
    private kiosk_apiService : KioskapiService
    ) { 
      this.translateConfigService.getDefaultLanguage();
      
      this.showDecimal= true;
      if(this.store.getCountry()) {
          let countryData = this.store.getCountry();
          this.currency   = countryData['master_country_currency_code'];
          if(countryData['master_country_currency_decimal_places'] == 0){
              this.showDecimal= false;
          }
      }
      this.ORDER_UUID = this.route.snapshot.queryParamMap.get('id');
      console.log("===> ORDER_UUID"+this.ORDER_UUID);
      this.getQRCode();
    }
  
    ngOnInit() {
      this.kiosk_apiService.log_events("SETTLEMENT")
      this.kiosk_apiService.audit_log_events("SETTLEMENT")

      //this.getQRCode();

      setTimeout(() => {
        this.messageSuccess = false;
    }, 5000 );

      this.time_left = auto_logout_timer;
      this.time_left_counter = setInterval(()=>{
        this.time_left -= 1;
        if (this.time_left == 0) {
          clearInterval(this.time_left_counter);
          this.store.setUserCart([]); 
          this.store.remove("formFields");
          this.store.remove("cart");
          this.alertController.dismiss();
          let Autologin = KIOSK_AUTO_LOGIN
          let terminalid = this.store.get('terminalid')
          
          this.store.hardlogout()
          
          location.href=Autologin + '/' + terminalid ;
          // return false;
        } 
      }, setIntervalTimeOut);
      
    }
  

    obj_qrcode
    getQRCode() {
      this.ORDER_UUID = this.route.snapshot.queryParamMap.get('id');

      this.order_info_uuid = localStorage.getItem('ellaOrderId');
      console.log(this.order_info_uuid)
      this.QR_order_info_uuid = localStorage.getItem('ellaOrderId') + '.';
      if (this.route.snapshot.queryParamMap.get('id')) {
        this.order_info_uuid = this.route.snapshot.queryParamMap.get('id');
        this.card_idi = this.route.snapshot.queryParamMap.get('c');
        this.bal = this.route.snapshot.queryParamMap.get('b');

        this.QR_order_info_uuid = this.route.snapshot.queryParamMap.get('id') + '.';
      }
     this.obj_qrcode =  this.kioskauthservice.apiReq('post', 'orderinfo/getbyuuid', { order_info_uuid: this.ORDER_UUID })
        .subscribe(res => this.order_info_uuid = res?.aaData?.order_number);

        this.getOrderDetails();
        /*
        //Wait for 10 sec and then redirect to the Homepage.
        setTimeout(() => {
          this.router.navigate(['tabs/tab1']);
        }, QRcodeTimeout);
        */
    }




    getSantizeUrl(url : string) { 
      return this.sanitizer.bypassSecurityTrustUrl(url); 
    }

    auto_print() {
        
      //let purl = 'https://crownfooddelivery.trigma.in/web/restapi/newinvoice?oid=EY1201_68';
      let purl=API_URL2+"restapi/newinvoice?oid="+this.orderInfo?.order_number;
      //this.print_url = this.sanitizer.bypassSecurityTrustResourceUrl(purl);
      //this.print_url = this.sanitizer.bypassSecurityTrustUrl(purl); 
      
      //let obj = document.getElementById("autoprintframe");
      //obj.setAttribute("src", this.print_url);
      


      //window.open(purl, '_system', "width=600,height=600,location=yes");

      // this.httpClient.get("https://localhost:3333/?purl="+purl);

      //this.httpClient.get(PRINT_API_URL + purl);
      console.log("Calling Print API :-",PRINT_API_URL + purl);

      this.printUrl = PRINT_API_URL + purl;
      
      this.api.printPdf( PRINT_API_URL + purl)
        .subscribe(res => {
          console.log("Print RESPONSE :-",res);
           
        },
        error =>{
          
          console.log("Print Error:-",error);

        })

        setTimeout(
          ()=>{
            this.store.setUserCart([]); 
            this.store.setUserCart([]); 
            this.store.remove("formFields");
            this.store.remove("cart");
            this.alertController.dismiss();
            
            // console.log("  ****s*********** LOGIN LOGOUT STARTED ******************");
            // this.store.hardlogout()
            // location.href=KIOSK_AUTO_LOGIN;
            // this.router.navigateByUrl('/kioskautologin/38b956674c4a9e35a00a38ce6d0fe6eb/3c928d3daba29b8bebeb77aa0356f577/1'); 
          }
        , PRINT_TIMEOUT);
        
    }
  
    ionViewWillEnter(){ 
      //this.getOrderDetails();
      ///this.check_balance();
      //this.callPaymentApi(this.route.snapshot.queryParamMap.get('id'));
    
    }
  

    
    obj_orderDetais

    getOrderDetails(){
      let order_info_uuid = this.route.snapshot.queryParamMap.get('id');
      
     
      let data = {
        //order_info_uuid: this.route.snapshot.queryParamMap.get('id')
        order_info_uuid: order_info_uuid
      };


      // this.pageLoaderService.pageLoaderPersistent(true)  //----------loader

      this.obj_orderDetais  = this.kiosk_apiService.order_details(data)
        .subscribe(res => {

          // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader

          this.orderInfo = res.aaData;
          
         
          console.log(" ===> "+this.orderInfo?.order_number)
          let render_order_number1 =  this.orderInfo?.order_number.split("_");

          if(render_order_number1[1] !=null && render_order_number1[1] !=undefined){
            let render_order_number = render_order_number1[1];
            this.render_order_number = render_order_number;
          }
          
          this.auto_print();
          console.log("ORDER NUMBER: "+this.render_order_number)
        },
        error =>{
          // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
          console.log(error)
        })
    }
  
    goBack(){
      this.store.setUserCart([]); 
      this.router.navigate([`home`]);
    }

    check_balance(){
        this.api.balance_check().subscribe(
          (r)=>{
            if (r['balance']){
              this.paymentResponse  = r;
            }      
          }
        );
    }
  
    callPaymentApi(orderUuid){
      let res: any = [];
      this.updateOrderStatus(orderUuid,res);
      // let data = {
      //   ff: "fgf"
      // }
      // this.kioskauthservice.paymentApiReq('post','SuicaPaymentRequest',data).subscribe(res => {
      //   if(res.status == 'Success') {
      //     this.paymentResponse = res;
      //     this.updateOrderStatus(orderUuid,res);
      //   } else {
      //     this.paymentRejectionAlert();
          
      //   }
      // })
    }
  
    obj_orderStatus; 

    updateOrderStatus(orderUuid,res){
      let data = {
        order_info_uuid: orderUuid,
        restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
        payment_response: res
      }
      // this.pageLoaderService.pageLoaderPersistent(true)  //----------loader

     this.obj_orderStatus = this.kiosk_apiService.update_order(data).subscribe(res => {

        // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader

        if(res.statusCode == 200) {
          this.store.remove("orderId");
          this.store.remove("totalAmount");
          this.store.remove("totalCartAmount");
          this.store.setCart([]); 
          this.store.remove("selectedItemsInCart"); 
          this.router.navigate([`settlement-complete`]);
          // this.router.navigateByUrl('/home');
        }   

        if  (res.statusCode != 200){
          if(res.aaData ==  false){
            this.technical_error()
          }
        }

      },
      error =>{
        this.technical_error
        // this.pageLoaderService.pageLoaderPersistent(false)  //----------loader
        console.log(error)
      }
      )
    }
  
    async paymentRejectionAlert() {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        mode: 'md',
        header: '',
        //message: '<p>Payment could not be completed. Please try again.</p>',
        message: '<p> もう一度、カードタッチして下さい </p>',
        buttons: [       
          {
            text: 'Back',
            handler: () => {
              this.alertController.dismiss();
              this.router.navigateByUrl('/payment-method');
            }
          }, {
            text: '',
            cssClass: 'cross',
            handler: () => {
              this.alertController.dismiss();
              this.router.navigateByUrl('/payment-method');
            }
          }
        ]
      });
      await alert.present();
    }

    go_to_maintenance() {
      location.href = "http://192.168.10.11:9001/maintenance";
    }

    new_order(){
      clearInterval(this.time_left_counter);
      console.log("  *************** LOGIN LOGOUT STARTED ******************");
      let Autologin = KIOSK_AUTO_LOGIN
      let terminalid = this.store.get('terminalid')
      
      this.store.hardlogout()
      
      location.href=Autologin + '/' + terminalid ;                      
    }

    ionViewDidLeave(){
      this.obj_orderStatus.unsubscribe();
      this.obj_orderDetais.unsubscribe();
      this.obj_qrcode.unsubscribe();
      clearInterval(this.time_left_counter);
      this.store.remove("sessionId")
    }

  
  async technical_error(){
      const alert =  this.alertController.create({
        cssClass: 'my-custom-class',
        mode: 'md',
        header: 'Oops!',
        message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
        buttons: [ 
    
        {
         text: '',
         cssClass: 'cross',
        handler: () => {
         this.alertController.dismiss();
        this.router.navigateByUrl('/Scan');
         }
       }
        ]
        });
         setTimeout(() => { this.router.navigateByUrl('/Scan'),this.alertController.dismiss();},5000);
         (await alert).present();
      }
  
  }
